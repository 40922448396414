// @flow
import React from "react"
import headerStyles from "./header.module.scss"

export default function Header() {
  return (
    <div className={headerStyles.bodyWrapper}>
      <div className={headerStyles.body}>under construction - stay tuned</div>
    </div>
  )
}
