// @flow
import React from "react"
import Container from "../components/MainContainer"
import Header from "../components/Header"
import Welcome from "../components/Welcome"

export default function IndexPage() {
  return (
    <Container>
      <Header />
      <Welcome />
    </Container>
  )
}
