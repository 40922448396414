// @flow
import React from "react"
import headerStyles from "./header.module.scss"

export default function Header() {
  return (
    <div className={headerStyles.header}>
      XIX{" "}
      <span aria-label="hand waving" role="img">
        👋🏻
      </span>
    </div>
  )
}
