// @flow
import React, { type Node as ReactNode } from "react"
import containerStyles from "./container.module.scss"

type Props = {
  children: ReactNode,
}

export default function MainContainer({ children }: Props) {
  return <div className={containerStyles.container}>{children}</div>
}
